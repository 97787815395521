.TagsEditor {
  & > div {
    max-width: 760px;
  }

  .tags-header {
    button {
      margin: 20px 20px 0 20px;
      font-size: 0.9em;
      font-weight: 500;

      &:hover {
        border-color: transparent;
      }

      &:focus {
        background-color: #28a745;
        box-shadow: none;
        border-color: transparent;
      }
    }
  }

  .tags-body {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;

    .tag {
      width: 350px;
      margin: 10px;

      .card-body {
        padding: 10px 10px 10px 20px;

        .tag-name {
          max-width: 230px;
          font-size: 0.9em;
          background-color: rgba(135, 141, 157, 0.22);
        }

        button {
          margin: 0;
          background-color: transparent;
          border-color: transparent;

          &:hover {
            background-color: #d3d9df !important;
            border-color: transparent;
          }

          &:focus {
            background-color: transparent;
            box-shadow: none;
            border-color: transparent;
          }
        }
      }
    }
  }
}
