.AdminPanel {
  display: flex;
  justify-content: center;
  margin: 20px 15vw;

  .admin-tabs {
    max-width: 1002px;
    min-width: 762px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin: 20px 0;
    overflow: hidden;

    *:focus {
      outline: none;
    }

    .admin-tabs-nav {
      .nav {
        display: flex;
        justify-content: space-evenly;

        & > a {
          font-weight: 600;
          text-align: center;
          min-width: 180px;
          padding: 20px;
          margin: 0 20px;
        }

        .nav-item {
          color: #495057;
        }

        .active,
        .nav-item,
        .nav-item:hover {
          border: 1px solid transparent !important;
          border-radius: 0;
        }

        .active,
        .nav-item:hover {
          color: #007bff !important;
          border-bottom: 2px solid #007bff !important;
        }
      }
    }
  }
}
