.ComparativeChart {
  min-height: 145px;
  padding: 20px;
  overflow-x: auto;

  .main-container {
    & > div {
      margin: 35px 0;
    }
  }

  .chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
    }

    .selected-name {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      & > span {
        margin: 5px 3px;
        white-space: nowrap;
      }
    }

    .tag-name {
      font-size: 0.9em;
      background-color: rgba(135, 141, 157, 0.22);
      border-radius: 0.25rem;
      margin-bottom: 4px;
      padding: 4px;
      font-weight: bold;

      & > span {
        margin: 0;
      }
    }

    .sum-money {
      margin-bottom: 30px;
      align-self: center;
      font-size: 1.2em;
    }

    .chart-tooltip {
      padding: 15px;
      border: hsl(0, 0%, 90%) 1px solid;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.8);

      p {
        padding: 0;
        margin: 0;
      }
    }

    .chart-legend {
      width: 600px;
      margin-top: 50px;

      .list-group-item:hover {
        background-color: #e9ecef;
      }

      .selected-legend {
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        justify-content: space-between;
        cursor: pointer;
        color: #212529;
        text-decoration: none;
        background-color: white;
        border-color: rgba(0, 0, 0, 0.125);

        & > div {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          span {
            white-space: nowrap;
          }
        }

        .numbers {
          margin-left: 50px;
        }
      }
    }
  }
}
