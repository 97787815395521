.ProportionalChart {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 145px;
  padding: 20px;

  .main-container {
    & > div {
      margin: 35px 0;
    }

    .chart-container {
      display: flex;
      justify-content: center;
      overflow-x: auto;

      & > div {
        display: flex;
        flex-direction: column;
      }

      .pie-chart {
        justify-content: center;
        align-items: center;

        .sum-money {
          margin-bottom: 20px;
          font-size: 1.2em;
        }
      }

      .chart-legend {
        margin: 50px 0 0 50px;

        .department-legend {
          display: flex;
          flex-wrap: nowrap;
          align-items: baseline;
          justify-content: space-between;
          padding: 8px 0;
          cursor: pointer;
          color: black;
          text-decoration: none;

          & > div {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            span,
            small {
              white-space: nowrap;
            }
          }

          .name {
            max-width: 300px;

            & > div {
              display: flex;
              flex-wrap: wrap;
            }

            i {
              margin-right: 16px;
              font-size: 1.2em;
            }
          }

          .numbers {
            margin-left: 50px;

            small {
              width: 33px;
              margin-left: 24px;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .departments-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    width: 800px;
  }
}
