.DepartmentChart {
  width: 400px;
  font-size: 0.9em;

  .chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 35px 0;

    .department-name {
      margin-bottom: 2px;
      white-space: nowrap;
    }

    .sum-money {
      margin-bottom: 20px;
      font-size: 1.2em;
    }

    .pie-chart {
      justify-content: center;
      align-items: center;
    }

    .chart-legend {
      margin: 10px 35px;
      align-self: stretch;

      .department-legend {
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        justify-content: space-between;
        padding: 8px 0;
        cursor: pointer;
        color: black;
        text-decoration: none;

        & > div {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          span,
          small {
            white-space: nowrap;
          }
        }

        .name {
          max-width: 300px;

          & > div {
            display: flex;
            flex-wrap: wrap;
          }

          i {
            margin-right: 16px;
            font-size: 1.2em;
          }
        }

        .numbers {
          margin-left: 35px;

          small {
            width: 33px;
            margin-left: 24px;
            text-align: right;
          }
        }
      }
    }
  }
}
