body {
  margin: 0;
  background-color: rgba(135, 141, 157, 0.22);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  min-width: 1080px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .form-modal-shadow {
    z-index: 1030;
  }

  .modal-backdrop {
    height: 100%;
    width: 100%;
  }

  .tooltip {
    .tooltip-inner {
      max-width: 350px;

      .tag-label {
        max-width: 180px;
        font-size: 0.9em;
        margin: 3px;
        padding: 3px;
        line-height: 200%;
      }
    }
  }

  .react-datepicker {
    font-size: 14px;
    border: hsl(0, 0%, 80%) 1px solid !important;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: white;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: hsl(0, 0%, 80%);
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-weight: 400;
    font-size: 14px;
  }

  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: #007bff;
  }

  .react-datepicker__month--selected:hover,
  .react-datepicker__month--in-selecting-range:hover,
  .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: #0062cc;
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today {
    font-weight: 400;
    border: #007bff 1px solid;
    border-radius: 0.3rem;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: white;
    color: black;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    border-radius: 0.3rem;
    background-color: #007bff;
    color: #fff;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover {
    background-color: #0062cc;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover {
    background-color: #0062cc;
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__quarter-text--in-selecting-range {
    background-color: rgba(0, 98, 204, 0.5) !important;
    color: white !important;
  }

  .react-datepicker__month--selecting-range .react-datepicker__day--in-range,
  .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
  .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range {
    background-color: hsl(0, 0%, 88%);

    &:hover {
      background-color: rgba(0, 98, 204, 0.5);
      color: white;
    }
  }

  .react-datepicker__month-text.react-datepicker__month--selected:hover,
  .react-datepicker__month-text.react-datepicker__month--in-range:hover,
  .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: #0062cc;
  }

  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    background-color: #f0f0f0;
  }

  .date-input-editor {
    height: 38px;
    width: 170px;
    background-color: hsl(0, 0%, 100%) !important;
    color: black !important;
    text-align: left !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    box-shadow: none !important;
    outline: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: hsl(0, 0%, 100%) !important;
      color: black !important;
    }

    & > div {
      display: flex;
      justify-content: space-between;
    }
  }

  .button-submit-editor {
    width: 80px;
    text-align: center;
  }
}
