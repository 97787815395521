.UsersEditor {
  .users-header {
    button {
      margin: 20px 20px 10px 20px;
      font-size: 0.9em;
      font-weight: 500;

      &:hover {
        border-color: transparent;
      }

      &:focus {
        background-color: #28a745;
        box-shadow: none;
        border-color: transparent;
      }
    }
  }

  table {
    margin-bottom: 0;

    thead td {
      padding-top: 5px;
      padding-bottom: 5px;
      border-top: 0;
    }

    td {
      padding: 15px;

      .super-user {
        background-color: rgba(135, 141, 157, 0.33);
      }

      button {
        margin: 0 2px;
        background-color: transparent;
        border-color: transparent;

        &:hover {
          background-color: #d3d9df !important;
          border-color: transparent;
        }

        &:focus {
          background-color: transparent;
          box-shadow: none;
          border-color: transparent;
        }
      }
    }

    .balance-money {
      white-space: nowrap;
    }
  }
}
