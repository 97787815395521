.Expenses {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 15vw;

  & > div {
    width: 100%;
  }

  .checkbox-expense {
    height: 38px;
    width: 38px;
    margin: 5px 10px;
    padding: 8px 10px 8px 11px;
    border-radius: 50%;
    cursor: pointer;

    i {
      width: 100%;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.08) !important;

      i {
        color: black !important;
      }
    }

    .smaller {
      font-size: 20.5px;
      padding-top: 2px;
    }

    .bigger {
      font-size: 18px;
      padding: 2px 0;
    }
  }

  .selected {
    background-color: rgba(195, 220, 246, 0.8);

    td {
      border-color: rgb(191, 215, 239) !important;
    }

    &:hover {
      background-color: rgb(195, 220, 246);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: auto;

    .dropdown,
    .btn-toolbar {
      button {
        border-radius: 50%;
        margin-bottom: 25px;
        color: #495057;

        &:hover {
          background-color: hsl(250, 21%, 95%);
        }

        &:focus {
          background-color: #f8f9fa;
          box-shadow: none;
          border-color: #f8f9fa;
        }
      }

      .dropdown-toggle::after {
        content: none;
      }
    }
  }

  .expenses-list {
    margin-bottom: 5px;
    white-space: nowrap;
    width: auto;
    min-width: 100%;

    .dollar-label {
      color: #495057;
    }

    button {
      margin: 0;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.08) !important;
        border-color: transparent;
      }

      &:focus {
        background-color: transparent;
        box-shadow: none;
        border-color: transparent;
      }
    }

    thead {
      th {
        background-color: #f2f2f5;
      }

      button {
        width: 41px;
      }

      .dropdown {
        position: static !important;

        a {
          padding: 10px 15px;
        }

        .tag-icon {
          width: 30px;
        }

        button:focus {
          background-color: rgba(0, 0, 0, 0.08);
        }

        .submenu {
          button {
            width: 100%;
            margin: 0;
            padding: 10px 15px;
            text-align: left;
            box-shadow: none !important;
            border-radius: 0;
            border: 0;
            pointer-events: none;

            &::before {
              content: none;
            }
          }

          .dropdown-menu {
            margin: 0;
          }

          .dropdown-item {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          .dropdown-item:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }

          .dropdown-item:active {
            color: black;
          }
        }
      }

      .dropdown-toggle::after {
        content: none;
      }

      th {
        height: 50px;
        padding: 5px 10px;
        vertical-align: middle;
      }
    }

    td {
      height: 60px;
      padding: 10px;
      vertical-align: middle;
      border-bottom: 2px solid #dee2e6;
    }
  }
}
