.ExpensesFilters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 0 20px 0;
  padding-right: 20px;
  min-width: 500px;

  & > div {
    min-width: 170px;
    margin: 5px;

    .form-label {
      margin: 0;
    }
  }

  .option-link {
    color: rgb(33, 37, 41);
    text-decoration: none;
  }

  .show-users {
    padding: 8px;
  }

  .btn {
    margin: 5px;
    padding: 1px;
    border: 0;

    &:focus {
      box-shadow: none;
    }

    svg {
      margin: 8px;

      path {
        fill: hsl(0, 0%, 40%);
      }
    }

    &:hover {
      svg path {
        fill: hsl(0, 0%, 20%);
      }
    }
  }

  .comment-search {
    height: 38px;
    width: 170px;
    padding-right: 30px;
    background-color: hsl(0, 0%, 100%);
    border: hsl(0, 0%, 100%) 1px solid !important;
    border-radius: 4px;
    box-shadow: none !important;
    outline: 0;
    font-size: 0.9rem;
  }

  .form-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 7px;
    right: 5px;
    height: 24px;
    width: 24px;

    svg {
      path {
        fill: hsl(0, 0%, 40%);
      }
    }

    &:hover {
      svg path {
        fill: hsl(0, 0%, 20%);
      }
    }
  }

  .show-users {
    font-size: 0.9rem;

    label {
      padding-top: 2px;
    }
  }

  .date-input {
    height: 38px;
    width: 170px;
    margin: 0;
    padding: 6px 30px 6px 10px;
    background-color: hsl(0, 0%, 100%);
    color: #495057;
    text-align: left;
    border: hsl(0, 0%, 100%) 1px solid !important;
    border-radius: 4px;
    box-shadow: none !important;
    outline: 0;
    font-size: 0.9rem;

    &:hover,
    &:active,
    &:focus {
      background-color: hsl(0, 0%, 100%) !important;
      color: #495057 !important;
    }
  }
}
