.Pages {
  margin-top: 20px;

  .page-item {
    cursor: pointer;

    .page-link {
      color: black;
    }
  }

  .disabled {
    cursor: default;
  }

  .page-limit {
    .page-limit-container {
      width: 80px;

      & > div {
        border: 0;
        box-shadow: 0 0 0 0;
      }
    }
  }
}
