.Charts {
  display: flex;
  justify-content: center;
  margin: 20px 15vw;

  .charts-tabs {
    max-width: 1002px;
    min-width: 862px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    margin: 20px 0;
    overflow: hidden;

    *:focus {
      outline: none;
    }

    .charts-tabs-nav {
      .nav {
        display: flex;
        justify-content: space-evenly;

        & > a {
          font-weight: 600;
          text-align: center;
          min-width: 200px;
          padding: 20px;
          margin: 0 20px;
        }

        .nav-item {
          color: #495057;
        }

        .active,
        .nav-item,
        .nav-item:hover {
          border: 1px solid transparent !important;
          border-radius: 0;
        }

        .active,
        .nav-item:hover {
          color: #007bff !important;
          border-bottom: 2px solid #007bff !important;
        }
      }
    }
  }

  .ChartFilters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    margin: 0 0 10px 0;
    min-width: 500px;

    & > div {
      min-width: 170px;
      margin: 1px 5px 10px 5px;
    }

    .clear-button {
      margin: 1px 5px 10px 5px;
      padding: 0;
      border-color: hsl(0, 0%, 80%);

      &:focus {
        box-shadow: none;
      }

      svg {
        margin: 8px;

        path {
          fill: hsl(0, 0%, 40%);
        }
      }

      &:hover {
        svg path {
          fill: hsl(0, 0%, 20%);
        }
      }
    }

    .date-input {
      height: 38px;
      width: 170px;
      padding: 6px 30px 6px 12px;
      background-color: hsl(0, 0%, 100%);
      color: black;
      text-align: left;
      border: hsl(0, 0%, 80%) 1px solid !important;
      border-radius: 4px;
      box-shadow: none !important;
      outline: 0;
      font-size: 0.9rem;

      &:hover,
      &:active,
      &:focus {
        background-color: hsl(0, 0%, 100%) !important;
        color: black !important;
      }
    }

    .date-input-disabled {
      background-color: hsl(0, 0%, 95%);
      color: hsl(0, 0%, 50%);
      cursor: default !important;

      &:hover,
      &:active,
      &:focus {
        background-color: hsl(0, 0%, 95%) !important;
        color: hsl(0, 0%, 50%) !important;
      }
    }

    .form-clear {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 7px;
      right: 5px;
      height: 24px;
      width: 24px;

      svg {
        path {
          fill: hsl(0, 0%, 40%);
        }
      }

      &:hover {
        svg path {
          fill: hsl(0, 0%, 20%);
        }
      }
    }
  }
}
