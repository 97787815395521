.UserCard {
  background-color: #cce5ff !important;

  .card-header {
    & > div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .super-user {
    background-color: rgba(253, 253, 254, 0.4);
  }
}
