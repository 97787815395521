.DepartmentsEditor {
  .disabled {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .disabled:hover {
    background-color: transparent !important;
    cursor: default;
  }

  .departments-header {
    button {
      margin: 20px 20px 0 20px;
      font-size: 0.9em;
      font-weight: 500;

      &:hover {
        border-color: transparent;
      }

      &:focus {
        background-color: #28a745;
        box-shadow: none;
        border-color: transparent;
      }
    }
  }

  .list-group {
    .list-group-item {
      border: 1px solid transparent;
    }
  }

  .list-group,
  .card {
    button {
      margin: 0 2px;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: #d3d9df !important;
        border-color: transparent;
      }

      &:focus {
        background-color: transparent;
        box-shadow: none;
        border-color: transparent;
      }
    }
  }

  .max {
    font-size: 0.9rem;

    i {
      font-size: 0.9rem;
    }

    .card {
      border: 0;
      margin: 5px 50px 15px 50px;

      .cat {
        min-height: 38px;
        padding: 0 2px 0 10px;
      }
    }
  }
}
