.container {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 15px;
}

.form-sign-in {
  max-width: 330px;
  padding: 15px 15px 10px 15px;
  margin: 0 auto;
}

input,
input::-webkit-input-placeholder {
  font-size: 15px;
}

.form-sign-in .form-sign-in-heading,
.form-sign-in .checkbox {
  margin-bottom: 10px;
}

.form-sign-in-heading {
  font-size: 22px;
  padding: 10px;
}

.form-sign-in .checkbox {
  font-weight: normal;
}

.form-sign-in .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}

.form-sign-in .form-control:focus {
  z-index: 2;
}

.form-sign-in input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-sign-in input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.reg input[type="email"] {
  margin-bottom: -1px;
  border-radius: 0;
}

.form-sign-in input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-sign-in p {
  margin: 10px 0 0 0;
}
